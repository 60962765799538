.btn-group .btn:hover {
  background-color: #5894ee;
  /* background-color: #0b5dd7a5; */
  /* outline: red 1px solid; */
}

.btn-group input:checked+label:hover{
  background-color: #0b5ed7;
}

#course-designations.btn-group .btn:hover {
  background-color: #6c757dc2;
}
 
#course-designations.btn-group input:checked+label:hover{
  background-color: #565e64;
}